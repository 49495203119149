<template>
    <v-main>
        <BackOnTop showDelete @click:delete="handleDeteleLb" />
        <PageTitleBar title="編輯寶貝資料" />
        <v-container class="register-container px-4 mb-4">
            <v-form ref="form" class="" v-model="valid" lazy-validation>
                <v-row class="mt-0">
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInput
                            v-model="form.alias"
                            label="寶貝暱稱"
                            iconName="ic24Pname.svg"
                            :rules="[() => !!form.alias || '請輸入寶貝暱稱']"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.type"
                            label="寶貝種類"
                            iconName="ic24Breed.svg"
                            :rules="[() => !!form.type || '請輸入寶貝種類']"
                            :items="petTypeList"
                            :options="petTypeList"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.breed"
                            label="寶貝品種"
                            iconName="ic24Breed.svg"
                            :rules="[() => !!form.breed || '請輸入寶貝品種']"
                            :items="
                                petBreedList.filter(function (obj) {
                                    if (obj.type == form.type) return true;
                                    else return false;
                                })
                            "
                            :options="
                                petBreedList.filter(function (obj) {
                                    if (obj.type == form.type) return true;
                                    else return false;
                                })
                            "
                            required
                        />
                    </v-col>

                    <v-col cols="6" sm="6" class="py-0 mb-4">
                        <FancySelect
                            label="寶貝生日 年"
                            v-model="form.birthday_year"
                            iconName="ic24Birthday.svg"
                            :options="yearOptions"
                            :items="yearOptions"
                        />
                    </v-col>
                    <v-col cols="3" sm="3" class="py-0 mb-4">
                        <FancySelect
                            label="月"
                            v-model="form.birthday_month"
                            :items="[
                                {
                                    text: '月',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                            ]"
                            :options="[
                                {
                                    text: '月',
                                    value: '',
                                    selected: true,
                                    disabled: true,
                                },
                                { text: '01', value: '01' },
                                { text: '02', value: '02' },
                                { text: '03', value: '03' },
                                { text: '04', value: '04' },
                                { text: '05', value: '05' },
                                { text: '06', value: '06' },
                                { text: '07', value: '07' },
                                { text: '08', value: '08' },
                                { text: '09', value: '09' },
                                { text: '10', value: '10' },
                                { text: '11', value: '11' },
                                { text: '12', value: '12' },
                            ]"
                        />
                    </v-col>
                    <v-col cols="3" sm="3" class="py-0 mb-4">
                        <FancySelect
                            label="日"
                            v-model="form.birthday_day"
                            :items="dayOptions"
                            :options="dayOptions"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.gender"
                            label="寶貝性別"
                            iconName="ic24PetGender.svg"
                            :rules="[() => !!form.gender || '請輸入寶貝性別']"
                            :items="[
                                { text: '公', value: '01' },
                                { text: '母', value: '02' },
                                { text: '其他', value: '03' },
                            ]"
                            :options="[
                                { text: '公', value: '01' },
                                { text: '母', value: '02' },
                                { text: '其他', value: '03' },
                            ]"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.body"
                            label="寶貝體型"
                            iconName="ic24PetBody.svg"
                            :rules="[() => !!form.body || '請輸入寶貝體型']"
                            :items="[
                                { text: '小型犬', value: '2' },
                                { text: '中型犬', value: '4' },
                                { text: '大型犬', value: '6' },
                                { text: '幼貓', value: '7' },
                                { text: '成貓', value: '8' },
                            ]"
                            :options="[
                                { text: '小型犬', value: '2' },
                                { text: '中型犬', value: '4' },
                                { text: '大型犬', value: '6' },
                                { text: '幼貓', value: '7' },
                                { text: '成貓', value: '8' },
                            ]"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInput
                            v-model="form.comb"
                            label="毛色"
                            iconName="ic24Comb.svg"
                            :rules="[() => !!form.comb || '請輸入寶貝毛色']"
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyRadio
                            row
                            v-model="form.isNeutered"
                            :items="[
                                { label: '已結紮', value: '1' },
                                { label: '未結紮', value: '0' },
                            ]"
                        />
                    </v-col>
                </v-row>
                <FancyButton
                    class="mb-6"
                    label="儲存修改"
                    width="150"
                    height="48"
                    @click="saveEdit"
                />
            </v-form>
        </v-container>
        <FancyDialog v-model="isDeleteOpened">
            <v-container fluid class="dialog_pet_delete"
                ><v-form ref="delete_reaspn_form" v-model="deleteValid">
                    <p class="title mb-3">確定要刪除寶貝資料?</p>
                    <p class="desc mb-6">
                        親愛的會員，您是否要刪除寶貝「{{ form.alias }}」的資料
                    </p>
                    <div>
                        <p class="mb-2">移除原因</p>
                        <FancyRadio
                            row
                            v-model="deletePetReason"
                            class="radio_group f_sm"
                            flexDirection="column"
                            :items="[
                                {
                                    label: '寶貝已成為小天使',
                                    value: '寶貝已成為小天使',
                                },
                                {
                                    label: '寶貝已轉交由他人照顧',
                                    value: '寶貝已轉交由他人照顧',
                                },
                                { label: '其他', value: '其他' },
                            ]"
                            :rules="[
                                () => !!deletePetReason || '請選擇移除原因',
                            ]"
                            required
                        />
                        <FancyTextarea
                            height="100"
                            v-if="deletePetReason == '其他'"
                            placeholder="其他原因"
                            v-model="deletePetReasonElse"
                            class="textarea_else"
                        />
                    </div>

                    <div class="footer d-flex justify-center">
                        <FancyButton
                            isGreen
                            class="btn mr-3"
                            label="取消"
                            @click="isDeleteOpened = false"
                        />
                        <FancyButton
                            class="btn"
                            color="primary"
                            label="確認"
                            @click="deletePet"
                        /></div
                ></v-form>
            </v-container>
        </FancyDialog>
    </v-main>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import PageTitleBar from '@/components/PageTitleBar.vue';
import FancyButton from '@/components/FancyButton.vue';
import FancyInput from '@/components/FancyInput.vue';
import FancySelect from '@/components/FancySelect.vue';
import FancyRadio from '@/components/FancyRadio.vue';
import FancyDialog from '@/components/FancyDialog.vue';
import FancyTextarea from '@/components/FancyTextarea.vue';
import {
    KgGetPetList,
    KgUpdatePet,
    KgGetPetType,
    deletePet,
    KgGetPetBreed,
} from '@/apis/api.js';

export default {
    name: 'RegisterPet',
    components: {
        BackOnTop,
        PageTitleBar,
        FancyButton,
        FancyInput,
        FancySelect,
        FancyRadio,
        FancyDialog,
        FancyTextarea,
    },
    data: () => ({
        valid: false,
        deleteValid: '',
        isDeleteOpened: false,
        form: {
            birthday_year: '',
            birthday_month: '',
            birthday_day: '',
            birthday: '2019-04-30',
        },
        isAgreeRegisterContract: false,
        deletePetReason: null,
        deletePetReasonElse: '',
        petTypeList: [],
        petBreedList: [],
        id: '',
        yearOptions: [
            {
                text: '生日 年',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
        dayOptions: [
            {
                text: '日',
                value: '',
                selected: true,
                disabled: true,
            },
        ],
    }),
    created() {
        var parent = this;
        for (
            let i = new Date().getFullYear();
            i >= (new Date().getFullYear() - 100);
            i--
        ) {
            this.yearOptions.push({
                text: `${i} (民${i-1911})`,
                value: `${i}`,
            });
        }
        this.id = this.$route.query.id;
        KgGetPetList(
            localStorage.getItem('phone'),
            localStorage.getItem('member_no')
        ).then(function (rsp) {
            if (rsp) {
                parent.babyList = [];
                for (let i in rsp) {
                    if (parent.id == rsp[i].PET_SeqNo) {
                        var birth = '';
                        if (rsp[i].PET_Birth)
                            birth =
                                rsp[i].PET_Birth.slice(0, 4) +
                                '-' +
                                rsp[i].PET_Birth.slice(4, 6) +
                                '-' +
                                rsp[i].PET_Birth.slice(6, 8);
                        console.log(birth);
                        parent.form = {
                            id: rsp[i].PET_SeqNo,
                            alias: rsp[i].PET_Name,
                            gender: rsp[i].PET_Sex,
                            birthday: birth,
                            birthday_year:  rsp[i].PET_Birth.slice(0,4),
                            birthday_month: rsp[i].PET_Birth.slice(4,6),
                            birthday_day: rsp[i].PET_Birth.slice(6, 8),
                            type: rsp[i].PET_Type,
                            breed: rsp[i].PET_Breed,
                            isNeutered: rsp[i].PET_Fix,
                            body: rsp[i].PET_Size,
                            comb: rsp[i].PET_Color,
                        };
                    }
                }
            }
        });
        KgGetPetBreed(localStorage.getItem('phone')).then(function (rsp) {
            var result = rsp.map(function (obj) {
                return {
                    type: obj.PET_Type,
                    text: obj.PET_BreedName,
                    value: obj.PET_Breed,
                };
            });
            parent.petBreedList = result;
        });
        KgGetPetType(localStorage.getItem('phone')).then(function (rsp) {
            var result = rsp.map(function (obj) {
                return {
                    text: obj.PET_TypeName,
                    value: obj.PET_Type,
                };
            });
            parent.petTypeList = parent.petTypeList.concat(result);
        });
    },
    watch: {
        deletePetReason() {
            console.log(this.deletePetReason);
        },
        birthday_year_month: function () {
            if (this.form.birthday_year && this.form.birthday_month) {
                var days = this.daysInMonth(
                    this.form.birthday_year,
                    parseInt(this.form.birthday_month)
                );
                this.dayOptions = [
                    {
                        text: '日',
                        value: '',
                        selected: true,
                        disabled: true,
                    },
                ];
                for (let i = 1; i <= days; i++) {
                    this.dayOptions.push({
                        text: i < 10 ? `0${i}` : `${i}`,
                        value: i < 10 ? `0${i}` : `${i}`,
                    });
                }
            }
        },
        birthday_year_month_day: function() {
            this.form.birthday = `${this.form.birthday_year}-${this.form.birthday_month}-${this.form.birthday_day}`;
        }
    },
    computed:{
        birthday_year_month(){
            return this.form.birthday_year + this.form.birthday_month
        },
        birthday_year_month_day(){
            return this.form.birthday_year + this.form.birthday_month + this.form.birthday_day
        }
    },
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
    methods: {
        daysInMonth(year, month) {
            console.log("daysInMonth",year,month)
            return new Date(year, month, 0).getDate();
        },
        saveEdit() {
            const valid = this.$refs.form.validate();
            var parent = this;

            if (valid) {
                var phone = localStorage.getItem('phone');
                var member_no = localStorage.getItem('member_no');
                if (valid && phone && member_no) {
                    KgUpdatePet(phone, member_no, parent.form).then(function (
                        rsp
                    ) {
                        if (rsp) {
                            parent.$router.push('/pet');
                        }
                    });
                }
            }
        },
        handleDeteleLb() {
            this.isDeleteOpened = true;
        },
        deletePet() {
            const valid = this.$refs.delete_reaspn_form.validate();
            var parent = this;
            if (valid) {
                console.log('刪除寵物', parent.deletePetReason);
                var reason = parent.deletePetReason;
                if (reason == '其他') reason = parent.deletePetReasonElse;
                deletePet(
                    localStorage.getItem('phone'),
                    localStorage.getItem('password'),
                    parent.id,
                    reason
                ).then(function (rsp) {
                    if (rsp) {
                        parent.$router.push('/pet');
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.register-container {
    margin: 12px 0;
    padding: 10px 0 0 0;
}

a.skip {
    text-decoration-line: underline;
    color: $color_main_second;
}

div.dialog_pet_delete {
    p.title {
        color: $color_main_first;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        letter-spacing: -0.3px;
        font-weight: normal;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        color: $color_main_black;
        text-align: left;
        padding-left: 24px;
        padding-right: 24px;
    }

    .radio_group {
        padding-left: 20px;
        padding-right: 20px;
        transition: all 0.3s;
        &.error--text {
            margin-bottom: 15px;
        }
        * {
            color: $color_main_grey_dark;
        }
        .v-messages {
            margin-left: 0 !important;
        }
    }

    .textarea_else {
        width: calc(100% - 48px);
        margin: -10px auto 0;
        .v-input__slot {
            background-color: #f9f6f4 !important;
            box-shadow: none;
        }
    }

    > .footer {
        .btn,
        a {
            height: 50px;
            width: 100%;
            @media screen and (max-width: 374px) {
                max-width: 118px;
            }
            @media screen and (min-width: 375px) {
                max-width: 145.5px;
            }
        }
        a {
            font-size: 16px;
            font-weight: 500;
            border-radius: 25px;
            background-color: #FF9F48 !important;
        }
    }
}
</style>
